import { ButtonWish } from "./components/button-wish";
import { Select } from "./components/select";
import { Form } from "./components/form";
import { ButtonSubmit } from "./components/button-submit";
import { ButtonArchive } from "./components/button-archive";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { useCardCtx } from "../../card-context";
import { ClickTooltip } from "src/app/components/tooltip";
import styles from "src/app/components/auction-card/lg/atoms.form/components/form.module.css";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { FcInfo } from "react-icons/fc";
import { PiWarningDuotone, PiInfoDuotone, PiInfo } from "react-icons/pi";
import { useMedia } from "react-use";
import InfoMessage from "./components/info-message";
import useAuctionStatus from "src/app/hooks/useAuctionStatus";
import { useMyUser } from "src/app/hooks/useMyUser";
import AddInfoAndBuyDialog from "../../atoms/add-info-and-buy-dialog";
import { useState } from "react";
import StatusBadge from "./components/status-badge";

export const ForSingleLot = () => {
  const isWide = useMedia("(min-width: 1044px)");
  const cardCtx = useCardCtx();
  const auction = cardCtx.auction as AuctionOfLot;
  const { price, setPrice, handleSubmit, isMakeABidLoading } = cardCtx;

  const {
    isEnabledMaxBid,
    isFinished,
    isFirstBid,
    isSingleBid,
    isOutbid,
    status,
  } = useAuctionStatus({ auction, price });

  const shouldNotBeInWishlist =
    !!auction.myLastPendingBid && auction.isWishlist;
  const showArchiveAndWish = !isFinished && !auction.myLastPendingBid;
  const currentPriceOption = {
    value: auction.currentPrice,
    label: formatCurrency(auction.currentPrice),
  };

  const user = useMyUser();
  const hasCard = !user.checkUser.is_missing_card;
  const [showAddressAndCardModal, setShowAddressAndCardModal] = useState(false);

  return (
    <>
      {!!showAddressAndCardModal && (
        <AddInfoAndBuyDialog
          onClose={() => setShowAddressAndCardModal(false)}
          onBuy={() => {
            setShowAddressAndCardModal(false);
            cardCtx.handleSubmit();
          }}
        />
      )}
      <Form
        style={{ justifyContent: "flex-start" }}
        auction={auction}
        empLabel="Estimated Market Price"
        empTooltipContent="Estimated Market Price (EMP) is calculated by Crurated using a range of different sources, taking into account the condition and rarity of the bottles."
        totalLabel="Excl. of processing fees and taxes"
        totalTooltipContent="Excludes any processing fees, applicable taxes and/or duties and delivery charges. Please check our T&C or Contact the Customer Service for further details."
        price={price}
        quantity={1}
        bidInfos={
          !isFirstBid && (
            <div className={styles.bidInfos}>
              <div className={styles.info}>
                <div className={styles.label}>Your last offer</div>
                <StatusBadge>
                  {formatCurrency(auction.myLastBid?.bid_offer)}
                </StatusBadge>
              </div>

              <div className={styles.info}>
                <ClickTooltip
                  content={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 6,
                      }}
                    >
                      <StatusBadge variant="success">Highest bid</StatusBadge>
                      <StatusBadge variant="warn">You were outbid</StatusBadge>
                    </div>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1ch",
                    }}
                  >
                    <div className={styles.label}>Status</div>
                    <div>
                      <FcInfo style={{ display: "block" }} size={14} />
                    </div>
                  </div>
                </ClickTooltip>
                <StatusBadge
                  variant={
                    status === "won" || status === "top" ? "success" : "warn"
                  }
                >
                  {status === "won"
                    ? "You Won"
                    : status === "outbid"
                      ? "You were outbid"
                      : status === "top"
                        ? "Highest bid"
                        : "No offer"}
                </StatusBadge>
              </div>
              {!!isEnabledMaxBid && (
                <div className={styles.info}>
                  <div className={styles.label}>Your max bid is</div>
                  <StatusBadge variant="primary">
                    {formatCurrency(auction.user_max_bid_set)}
                  </StatusBadge>
                </div>
              )}
              {status === "outbid" && (
                <InfoMessage
                  isWide={isWide}
                  title="Offer outbid! Place a higher bid to secure item."
                  icon={
                    <PiWarningDuotone
                      style={{ display: "block", fontSize: 16 }}
                      color="#C87A06"
                    />
                  }
                />
              )}
            </div>
          )
        }
        inputLeft={
          <Select
            label="Current Price"
            value={auction.currentPrice}
            options={[currentPriceOption]}
            disabled
          />
        }
        inputRight={
          <Select
            icon={
              !!isEnabledMaxBid &&
              (!isSingleBid || status === "top") && (
                <PiInfo
                  style={{
                    display: "block",
                    fontSize: 14,
                    flexShrink: 0,
                  }}
                  color="#000"
                />
              )
            }
            label={
              !isEnabledMaxBid || !auction.canBid
                ? "Make your offer"
                : isFirstBid
                  ? isSingleBid
                    ? "Place bid"
                    : "Place Maximum Bid"
                  : isOutbid
                    ? isSingleBid
                      ? "Increase bid"
                      : "Place Maximum Bid"
                    : "Place Maximum Bid"
            }
            value={price}
            options={(() => {
              return isEnabledMaxBid && auction.canBid
                ? auction.priceOptions
                : !isFirstBid
                  ? [currentPriceOption]
                  : [auction.priceOptions[0]];
            })()}
            onChange={setPrice}
            disabled={isFinished || !auction.canBid}
          />
        }
        actionLeft={
          !!showArchiveAndWish && (
            <ButtonArchive
              isArchived={cardCtx.isArchived}
              isLoading={cardCtx.isArchiveLoading}
              onClick={cardCtx.toggleArchive}
            />
          )
        }
        actionCenter={
          <ButtonSubmit
            label={
              !isEnabledMaxBid
                ? "Submit a Bid"
                : isFirstBid
                  ? isSingleBid
                    ? "Place bid"
                    : "Place Maximum Bid"
                  : isOutbid
                    ? isSingleBid
                      ? "Increase bid"
                      : "Place Maximum Bid"
                    : price < auction.user_max_bid_set
                      ? "Decrease Maximum Bid"
                      : "Increase Maximum Bid"
            }
            forbidden={isFinished || price === auction.user_max_bid_set}
            isLoading={isMakeABidLoading}
            onClick={() =>
              (!user.addressInvoice.length || !hasCard) &&
              user.id_customer_role !== 14
                ? setShowAddressAndCardModal(true)
                : handleSubmit()
            }
          />
        }
        actionRight={
          (!!shouldNotBeInWishlist || !!showArchiveAndWish) && (
            <ButtonWish
              isWished={cardCtx.auction.isWishlist}
              onClick={cardCtx.toggleIsWished}
              isLoading={cardCtx.isWishLoading}
            />
          )
        }
        infoMessage={
          price === auction.user_max_bid_set ? (
            <InfoMessage
              isWide={isWide}
              title="You have already set the selected option as your maximum bid."
              icon={
                <PiWarningDuotone
                  style={{ display: "block", fontSize: 16 }}
                  color="#C87A06"
                />
              }
            />
          ) : !!isEnabledMaxBid && (!isSingleBid || status === "top") ? (
            <InfoMessage
              isWide={isWide}
              title="The system will automatically raise your bids for you at any stage."
              icon={
                <PiInfoDuotone
                  style={{
                    display: "block",
                    fontSize: 16,
                    flexShrink: 0,
                    marginTop: 3,
                  }}
                  color="#000"
                />
              }
            />
          ) : null
        }
      />
    </>
  );
};
