import { createCookieService } from "crustack/define-cookies";
import { useEffect } from "react";
import { env } from "src/env";

const cookieService = createCookieService();

export const useRefCookie = () => {
  useEffect(() => {
    set();
  });
  return get();
};

/**
 * set the ?ref=vivino search param in the cookies
 */
function set() {
  const searchParams = new URLSearchParams(window.location.search);
  const referral = searchParams.get("ref") ?? undefined;
  if (!referral) return;
  cookieService.set("ref", referral, {
    expires: 3,
    secure: env.isProd,
    domain: env.isProd ? "crurated.com" : "localhost",
  });
}

/**
 * read the ref cookie
 */
function get(): string | undefined {
  return cookieService.get("ref", {
    validate: (value) => (typeof value === "string" ? value : undefined),
  });
}
