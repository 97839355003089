import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { parseFlags } from "src/flags";

const PREFIX = "REACT_APP_FEATURE_";

type Options = {
  useEnv?: boolean;
  enableBraze?: boolean;
};

export const useFlags = ({
  useEnv = false,
  enableBraze = false, // TODO: handle braze https://www.braze.com/docs/developer_guide/platform_wide/feature_flags
}: Options = {}) => {
  const { data: flagsData } = useQuery({
    enabled: !useEnv,
  });

  const parsedFlags = useMemo(() => {
    // eslint-disable-next-line no-restricted-globals
    return parseFlags(useEnv ? process.env : flagsData, PREFIX);
  }, [flagsData, useEnv]);

  return parsedFlags;
};
