import { ButtonWish } from "./components/button-wish";
import { Select } from "./components/select";
import { useIsAuctionFinished } from "src/app/hooks/useIsAuctionFinished";
import { ButtonSubmit } from "./components/button-submit";
import { ButtonArchive } from "./components/button-archive";
import { isAuctionOfBarrel, isAuctionOfLot } from "src/app/models/auctions";
import { useCardCtx } from "../../card-context";
import { useMyUser } from "src/app/hooks/useMyUser";
import AddInfoAndBuyDialog from "../../atoms/add-info-and-buy-dialog";
import { useState } from "react";
import { FormCollection } from "./components/form-collection";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { ButtonShare } from "../../atoms/button-share";
import { AvailabilityLabel } from "./components/availability-label";

export const ForCollection = () => {
  const cardCtx = useCardCtx();
  const isFinished = useIsAuctionFinished(cardCtx.auction);
  const isEditingBid = !cardCtx.auction.canBid && cardCtx.auction.canEdit;

  const user = useMyUser();
  const isMissingCard = !!user.checkUser.is_missing_card;
  const isMissingAddress = !user.addressInvoice.length;
  const shouldShowAddressAndCardModal = isMissingAddress || isMissingCard;
  const [showAddressAndCardModal, setShowAddressAndCardModal] = useState(false);

  const onSubmitBidCallback = isEditingBid
    ? cardCtx.editBid
    : cardCtx.handleSubmit;

  const handleBid = () => {
    if (shouldShowAddressAndCardModal) {
      setShowAddressAndCardModal(true);
    } else {
      return onSubmitBidCallback();
    }
  };

  return (
    <>
      {!!showAddressAndCardModal && (
        <AddInfoAndBuyDialog
          onClose={() => setShowAddressAndCardModal(false)}
          onBuy={() => {
            onSubmitBidCallback();
            setShowAddressAndCardModal(false);
          }}
        />
      )}
      <FormCollection
        actionButtons={
          <>
            <ButtonShare size={30} />
            <ButtonWish
              isWished={cardCtx.isWished}
              onClick={cardCtx.toggleIsWished}
              isLoading={cardCtx.isWishLoading}
              variant="ghost"
              size={30}
            />
            <ButtonArchive
              isArchived={cardCtx.isArchived}
              isLoading={cardCtx.isArchiveLoading}
              onClick={cardCtx.toggleArchive}
              variant="ghost"
              size={30}
            />
          </>
        }
        auction={cardCtx.auction}
        headingLabel={`Starting price per ${
          isAuctionOfBarrel(cardCtx.auction)
            ? `${cardCtx.auction.options.quantities.mul} liters`
            : "lot"
        }`}
        empLabel={`Crurated Estimated Market Price per ${
          isAuctionOfBarrel(cardCtx.auction)
            ? `${cardCtx.auction.options.quantities.mul} L`
            : "lot"
        }`}
        empTooltipContent="Estimated Market Price (EMP) is calculated by Crurated using a range of different sources, taking into account the condition and rarity of the bottles."
        totalLabel="Excl. of processing fees and taxes"
        totalTooltipContent="Excludes any processing fees, applicable taxes and/or duties and delivery charges. Please check our T&C or Contact the Customer Service for further details."
        inputQty={
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Select
              label="Quantity"
              value={cardCtx.quantity}
              options={cardCtx.auction.quantityOptions}
              onChange={(option) => {
                cardCtx.setQuantity(option);
              }}
              disabled={isFinished}
            />
            {isAuctionOfLot(cardCtx.auction) &&
              !!cardCtx.auction.availability_label && (
                <AvailabilityLabel text={cardCtx.auction.availability_label} />
              )}
          </div>
        }
        inputPrice={
          <Select
            label={`Your offer per ${isAuctionOfLot(cardCtx.auction) ? "lot" : `${cardCtx.auction.options.quantities.mul} L`}`}
            value={cardCtx.price}
            options={cardCtx.auction.priceOptions}
            onChange={cardCtx.setPrice}
            disabled={isFinished}
          />
        }
        action={
          <ButtonSubmit
            label={
              isEditingBid
                ? "Edit your bid"
                : `Place a Bid at ${formatCurrency(cardCtx.price * cardCtx.quantity)}`
            }
            forbidden={!!isFinished}
            isLoading={cardCtx.isMakeABidLoading}
            onClick={() => handleBid()}
          />
        }
        action2={
          // Buy now feature always allows multiple purchases
          !!cardCtx.auction.isBuyNowEnabled && (
            <ButtonSubmit
              label={`Buy Now at ${formatCurrency(
                cardCtx.auction.buy_now_price! * cardCtx.quantity,
              )}`}
              forbidden={!!isFinished}
              isLoading={cardCtx.isMakeABidLoading}
              onClick={() => cardCtx.handleBuyNow()}
            />
          )
        }
      />
    </>
  );
};
