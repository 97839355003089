import { CSSProperties } from "react";
import { BannerCarousel } from "./banner-carousel";
import { BannerSlide } from "./banner-slide";
import bannerDesktop from "./lachaux-desktop.jpg";
import bannerMobile from "./lachaux-mobile.png";
import hdbDesktop from "./hdb-desktop.png";
import hdbMobile from "./hdb-mobile.png";
import { A } from "../a";

type Props = {
  style?: CSSProperties;
};

export function Banner({ style }: Props) {
  return (
    <BannerCarousel
      style={style}
      slides={[
        <BannerSlide
          key="banner-slide-1"
          srcDesktop={bannerDesktop}
          srcMobile={bannerMobile}
          alt="Lachaux banner"
          // the banner will be removed on this date
          endDateExcluded={{
            day: 20,
            month: 9,
            year: 2024,
          }}
          markup={
            <A to={`/weekly/auctions/all?winery=%5B"charles-lachaux"%5D`} />
          }
          /**
           * The markup takes the whole size of the slide.
           * Put eventual links here
           */
          // markup={<A to="https://a.b.c">Summer Sales 15% off</A>}
        />,
        <BannerSlide
          key="banner-slide-2"
          srcDesktop={hdbDesktop}
          srcMobile={hdbMobile}
          alt="Hdb banner"
          // the banner will be removed on this date
          endDateExcluded={{
            day: 20,
            month: 9,
            year: 2024,
          }}
          markup={<A to={`https://page.crurated.com/HDB2024`} />}
        />,
      ]}
    />
  );
}
