import { ReactNode, useState } from "react";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { PaymentIntentResult } from "@stripe/stripe-js";
import { StepPaymentMethod } from "./step-payment-method";
import { StepPaymentSettled } from "./step-payment-settled";
import { Dialog } from "src/app/components/dialog-2";
import { StepAddNewCard } from "./step-add-new-card";

type Props = {
  title: string;
  content: ReactNode | string;
  auction: AuctionOfBarrel | AuctionOfLot;
  onConfirm: () => void;
  onClose: () => void;
  bidDetails?: {
    price: number;
    quantity: number;
  };
  showTaxDisclaimer?: boolean;
};

export type PaymentStep = "payment-method" | "settled" | "add-new-card";

export default function ConfirmPaymentModal({ onClose, ...props }: Props) {
  const [currentStep, setStep] = useState<PaymentStep>("payment-method");
  const [paymentOutcome, setOutcome] = useState<
    PaymentIntentResult | undefined
  >();

  return (
    <Dialog
      width={360}
      onClosed={onClose}
      render={({ startClosing }) => (
        <>
          {currentStep === "settled" ? (
            <StepPaymentSettled onClose={onClose} outcome={paymentOutcome} />
          ) : currentStep === "add-new-card" ? (
            <StepAddNewCard onBack={() => setStep("payment-method")} />
          ) : (
            <StepPaymentMethod
              {...props}
              onAddNewCard={() => setStep("add-new-card")}
              onPaymentSettled={(outcome) => {
                setOutcome(outcome);
                setStep("settled");
              }}
              onClose={startClosing}
            />
          )}
        </>
      )}
    />
  );
}
