import { entries } from "lodash";
import { z } from "zod";

const flagsSchema = z
  .object({
    expressCheckoutUserId: z.string().transform((val) => {
      const parsedInt = Number.parseInt(val);
      return Number.isSafeInteger(parsedInt) ? parsedInt : undefined;
    }),
    expressCheckoutUserIds: z.string().transform((val) =>
      String(val)
        .split(",")
        .map((el) => Number.parseInt(String(el).trim()))
        .filter((el) => !isNaN(el) && !!el),
    ),
  })
  .partial();

export type FeatureFlags = z.infer<typeof flagsSchema>;

const defaultFlags: FeatureFlags = {};

export const parseFlags = (config: any, prefix: string = ""): FeatureFlags => {
  const parsedFlags = flagsSchema.safeParse(
    entries(config)
      .filter(([key]) => key.indexOf(prefix) === 0)
      .reduce(
        (obj, [key, value]) => {
          obj[key.replace(prefix, "")] = value;
          return obj;
        },
        {} as Record<string, any>,
      ),
  );

  if (parsedFlags.success) {
    return { ...defaultFlags, ...parsedFlags.data };
  }
  console.error(parsedFlags.error);
  return defaultFlags;
};
