import { Auction, isAuctionOfBarrel } from "src/app/models/auctions";
import styles from "./subtotal.module.css";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { Skeleton } from "src/app/components/skeleton";

export const Subtotal = ({
  auction,
  bidDetails,
  showTaxDisclaimer,
  fees,
}: {
  auction: Auction;
  fees: number;
  bidDetails?: {
    price: number;
    quantity: number;
  };
  showTaxDisclaimer?: boolean;
}) => {
  return (
    <div className={styles.root}>
      {!!bidDetails && (
        <div className={styles.totalContainer}>
          <div className={styles.row}>
            <span
              className={styles.label}
            >{`Subtotal ${bidDetails.quantity * (auction.options.quantities.mul || 1)} ${isAuctionOfBarrel(auction) ? "L" : bidDetails.quantity > 1 ? "lots" : "lot"}`}</span>
            <span className={styles.value}>
              {formatCurrency(bidDetails.price * bidDetails.quantity)}
            </span>
          </div>
          <div className={styles.row}>
            <span className={styles.label}>Fees</span>
            <span className={styles.value}>{formatCurrency(fees)}</span>
          </div>
          <hr className={styles.divider} />
          <div className={styles.row} style={{ marginTop: "8px" }}>
            <span className={styles.label}>You will pay</span>
            {!fees ? (
              <Skeleton.Text width={60} />
            ) : (
              <span className={`${styles.value} ${styles.bold}`}>
                {formatCurrency(bidDetails.price * bidDetails.quantity + fees)}
              </span>
            )}
          </div>
        </div>
      )}
      {!!showTaxDisclaimer && (
        <div className={styles.disclaimer}>
          Applicable taxes, duties, and VAT will be calculated at shipment based
          on destination country.
        </div>
      )}
    </div>
  );
};
