import {
  Elements,
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  PaymentIntentResult,
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
} from "@stripe/stripe-js";
import { useConfirmPaymentMutation } from "../../../../../../api/mutations_new_to_rename";
import { getStripe } from "../../../../../../hooks/useLoadStripe";
import { useMyUser } from "../../../../../../hooks/useMyUser";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";

const stripePromise = getStripe();

export const ExpressCheckoutButton = ({
  bidDetails,
  onPaymentSettled,
}: {
  bidDetails: {
    auction: AuctionOfBarrel | AuctionOfLot;
    fees: number;
    price: number;
    quantity: number;
    id_auction: number;
  };
  onPaymentSettled: (outcome: PaymentIntentResult | undefined) => void;
}) => {
  const priceInCents = Math.ceil(
    (bidDetails.price * bidDetails.quantity + bidDetails.fees) * 100,
  );

  return (
    <Elements
      options={{
        mode: "payment",
        amount: priceInCents,
        currency: "eur",
        loader: "always",
      }}
      stripe={stripePromise}
    >
      <RenderExpressCheckoutElement
        bidDetails={{ ...bidDetails, price: priceInCents }}
        onPaymentSettled={onPaymentSettled}
      />
    </Elements>
  );
};

function RenderExpressCheckoutElement({
  bidDetails,
  onPaymentSettled,
}: {
  bidDetails: {
    auction: AuctionOfBarrel | AuctionOfLot;
    price: number; //TODO: Rimuovere
    quantity: number;
    id_auction: number;
  };
  onPaymentSettled: (outcome: PaymentIntentResult | undefined) => void;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const confirmPaymentMutation = useConfirmPaymentMutation({
    onSettled: onPaymentSettled,
  });
  const user = useMyUser();

  if (!stripe || !elements) return;

  const onClick = ({ resolve }: StripeExpressCheckoutElementClickEvent) => {
    resolve({
      emailRequired: true,
    });
  };

  const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
    const { error: submitError } = await elements.submit();

    if (submitError) {
      return submitError.message;
    }

    return confirmPaymentMutation.mutate({
      buy_now: true,
      id: bidDetails.id_auction,
      id_user: user.id_user,
      quantity: bidDetails.quantity,
      type: bidDetails.auction.type_product,
      shipping_address: {
        name: event.billingDetails!.name,
        address1: event.billingDetails!.address.line1,
        locality: event.billingDetails!.address.city,
        administrative_area: event.billingDetails!.address.country,
        postal_code: event.billingDetails!.address.postal_code,
        country_code: event.billingDetails!.address.state,
      },
    });
  };
  return (
    <ExpressCheckoutElement
      options={{ layout: { maxColumns: 1 } }}
      onClick={onClick}
      onConfirm={onConfirm}
    />
  );
}

// const onShippingAddressChange = async ({ resolve, address }) => {
//   const response = await fetch("/calculate-shipping", {
//     data: JSON.stringify({
//       shippingAddress: address,
//     }),
//   });
//   const result = await response.json();
//   resolve({
//     lineItems: result.updatedLineItems,
//   });
// };
