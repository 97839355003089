import styles from "./add-info-and-buy-dialog.module.css";
import { useState } from "react";
import { Button } from "../../button";
import { Dialog } from "../../dialog-2";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useMyCardsQuery } from "src/app/api/payments";
import { AddCardForm } from "src/app/pages/my-profile/my-payments/add-card-form";
import { AddressForm } from "../../address-form/address-form";
import { PiWarningCircleDuotone } from "react-icons/pi";
import { getStripe } from "src/app/hooks/useLoadStripe";
import { Elements } from "@stripe/react-stripe-js";

type Props = {
  onClose: () => void;
  onBuy: () => void;
  isForAuction?: boolean;
};

type Step = "init" | "address" | "card";

const stripePromise = getStripe();

export default function AddInfoAndBuyDialog({
  onClose,
  onBuy,
  isForAuction,
}: Props) {
  const [step, setStep] = useState<Step>("init");
  const user = useMyUser();
  const { data: cardsData } = useMyCardsQuery({ staleTime: 2000 });
  const hasCard = !!cardsData?.length;
  const hasAddress = !!user.addressInvoice.length;
  const handleConfirm = () => {
    setStep(hasAddress ? "card" : "address");
  };

  const renderStep = () => {
    if (step === "init")
      return (
        <StepInit
          isForAuction={isForAuction}
          onCancel={onClose}
          onConfirm={handleConfirm}
        />
      );
    if (step === "address")
      return (
        <StepAddress
          onClose={onClose}
          onSuccess={() => (hasCard ? onBuy() : setStep("card"))}
        />
      );
    return <StepCard onClose={onClose} onSuccess={() => onBuy()} />;
  };

  return (
    <Dialog
      width={step === "init" ? 400 : 570}
      onClosed={onClose}
      render={renderStep}
    />
  );
}

function StepInit({
  onCancel,
  onConfirm,
  isForAuction,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  isForAuction?: boolean;
}) {
  return (
    <>
      <div className={styles.step1}>
        <PiWarningCircleDuotone size={40} color="dodgerblue" />
        <h3 style={{ marginBottom: "0", marginTop: "12px" }}>
          Information Required
        </h3>
        <div className={styles.step1Text}>
          {isForAuction
            ? "To proceed with your offer, please enter your billing address and a valid credit card."
            : "To proceed with your purchase, please enter your billing address and a valid credit card."}
        </div>
      </div>
      <div className={styles.step1Button}>
        <Button
          variant="outlined"
          label="Cancel"
          type="button"
          onClick={onCancel}
        />
        <Button
          variant="contained"
          label="Continue"
          type="button"
          onClick={onConfirm}
        />
      </div>
    </>
  );
}

function StepAddress({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}) {
  return (
    <AddressForm
      addressType="invoice"
      onCancel={onClose}
      onError={onClose}
      onSuccess={onSuccess}
    />
  );
}

function StepCard({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}) {
  return (
    <Elements stripe={stripePromise}>
      <AddCardForm
        className={styles.stepCardForm}
        onError={onClose}
        onSuccess={onSuccess}
      />
    </Elements>
  );
}
