import styles from "./index.module.css";
import { useIsMutating } from "@tanstack/react-query";
import { useBidProcessingFeesQuery } from "src/app/api/queries_to_rename_later";
import { Button } from "src/app/components/button";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { PaymentIntentResult } from "@stripe/stripe-js";
import { Subtotal } from "./subtotal";
import { ExpressCheckoutButton } from "../express-checkout-button";
import { RadioCard } from "./radio-card";
import { MdOutlineLock } from "react-icons/md";
import { useMyCardsQuery } from "src/app/api/payments";
import { FeatureFlag } from "src/app/components/feature-flag";
import { useMyUser } from "src/app/hooks/useMyUser";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";

type Props = {
  title: string;
  auction: AuctionOfBarrel | AuctionOfLot;
  onClose: () => void;
  onAddNewCard: () => void;
  onConfirm: () => void;
  bidDetails?: {
    price: number;
    quantity: number;
  };
  showTaxDisclaimer?: boolean;
  onPaymentSettled: (paymentOutcome: PaymentIntentResult | undefined) => void;
};

export const StepPaymentMethod = ({
  bidDetails,
  auction,
  title,
  showTaxDisclaimer,
  onClose,
  onAddNewCard,
  onConfirm,
  onPaymentSettled,
}: Props) => {
  const user = useMyUser();
  const { data: myCards = [], isLoading: isLoadingMyCards } = useMyCardsQuery();
  const { data: dataFees = undefined } = useBidProcessingFeesQuery(
    {
      auction_type: auction.type_product,
      id_auction: auction.id,
      quantity: bidDetails?.quantity || 1,
      single_bid: bidDetails?.price || 1,
    },
    { enabled: !!bidDetails },
  );
  const isMutating = !!useIsMutating();
  const isMissingAddress = !user.addressInvoice.length;

  return (
    <div className={styles.content}>
      <div>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <Subtotal
        auction={auction}
        fees={dataFees}
        bidDetails={bidDetails}
        showTaxDisclaimer={showTaxDisclaimer}
      />
      {!myCards.length ? (
        <p style={{ color: "#4B4A54" }}>
          You don&apos;t have a credit card linked to your account. If you want
          to pay with a credit card, please add one in your account&apos;s
          private area.
        </p>
      ) : isMissingAddress ? (
        <p style={{ color: "#4B4A54" }}>
          You don&apos;t have an invoice address for your account. If you want
          to pay with a credit card, please add one in your account&apos;s
          private area.
        </p>
      ) : (
        <div className={styles.rootRadio}>
          <RadioCard
            onClose={onClose}
            myCards={myCards}
            isLoading={isLoadingMyCards}
          />
        </div>
      )}
      <div className={styles.buttonsWrapper}>
        {myCards.length && !isMissingAddress ? (
          <Button
            as={"button"}
            className={styles.button}
            label="Pay with Credit Card"
            disabled={isMutating || !dataFees}
            onClick={onConfirm}
            isLoading={isMutating}
          />
        ) : isMissingAddress ? (
          <Button
            as={A}
            className={styles.button}
            to={path.profile.addresses}
            label="Continue to add new address"
          />
        ) : (
          <Button
            as={"button"}
            variant="outlined"
            className={styles.button}
            label="+ Add a debit/credit card"
            disabled={isMutating}
            onClick={onAddNewCard}
            isLoading={isMutating}
          />
        )}
        <FeatureFlag
          displayWhen={({ expressCheckoutUserId, expressCheckoutUserIds }) =>
            user.id_user === expressCheckoutUserId ||
            (!!expressCheckoutUserIds &&
              expressCheckoutUserIds.includes(user.id_user))
          }
        >
          {() => (
            <>
              {!!bidDetails && !isNaN(dataFees) && (
                <ExpressCheckoutButton
                  onPaymentSettled={onPaymentSettled}
                  bidDetails={{
                    auction,
                    fees: dataFees,
                    ...bidDetails,
                    id_auction: auction.id_auction,
                  }}
                />
              )}
            </>
          )}
        </FeatureFlag>
      </div>
      <PaymentSecureLabel />
    </div>
  );
};

function PaymentSecureLabel() {
  return (
    <div className={styles.label}>
      <span>
        <MdOutlineLock size={20} />
      </span>
      <span>Payment are secure and encrypted</span>
    </div>
  );
}
