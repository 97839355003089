import { ReactNode } from "react";
import { useFlags } from "src/app/hooks/use-flags";
import { env } from "src/env";
import { FeatureFlags } from "src/flags";
import { DevTooltip } from "./dev-tooltip";

type Props = {
  displayWhen: (features: FeatureFlags) => boolean;
  children: (flags: FeatureFlags) => ReactNode;
  fallback?: ReactNode;
  showHelper?: boolean;
};

export const FeatureFlag = ({
  displayWhen,
  children,
  fallback,
  showHelper = false,
}: Props) => {
  const flags = useFlags({ useEnv: true });
  const isDisplayed = displayWhen(flags);

  return (
    <>
      {isDisplayed ? children(flags) : !!fallback && fallback}
      {!!env.isDev && !!showHelper && <DevTooltip displayed={isDisplayed} />}
    </>
  );
};
